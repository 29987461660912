html, body {
  margin:0;
  padding: 0;
  width:100%;
  height:100%;
}
.transparent{
  background: transparent !important;
  border: 0px !important;
  height: auto !important;
}
.ddNone{
  display: none !important;
}